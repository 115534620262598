import HomePageQueryMapper from '../../../../common/helpers/mappers/HomePageQueryMapper';
import type { HomePageQuery } from '../../../../common/models/system/home/queries/HomePageQuery';
import BasePublicGetService from '../../../../framework/base/client/services/base/BasePublicGetService';

abstract class BaseHomeEntitiesService<TDataModel> extends BasePublicGetService {

  public search = async (query: HomePageQuery | undefined): Promise<TDataModel> => {
    return this.sendGet<TDataModel>(query ? this.stringify(query) : '');
  };

  public more = async (query: HomePageQuery): Promise<TDataModel> => {
    return this.sendGet<TDataModel>(`/more${this.stringify(query)}`);
  };

  protected stringify = (query: HomePageQuery): string => {
    return (new HomePageQueryMapper()).stringifyForAPI(query);
  };
}

export default BaseHomeEntitiesService;
