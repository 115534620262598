import { createPath, type Path } from 'react-router-dom';
import BasePageContentService, { type BasePageContentModel } from '../../../../../framework/base/common/services/content/abstract/BasePageContentService';
import type { LocationDetailWithGeoApiModel } from '../../../../../framework/gis/common/models/api/location/LocationDetailWithGeoApiModel';
import type { LocationListItemWithParentApiModel } from '../../../../../framework/gis/common/models/api/location/LocationListItemWithParentApiModel';
import type EntityTypeEnum from '../../../../constants/enums/EntityTypeEnum';
import HomePageUrlQueryMapper from '../../../../helpers/mappers/HomePageUrlQueryMapper';
import type { HomePageQuery } from '../../../../models/system/home/queries/HomePageQuery';

abstract class EntitiesListPageContentService extends BasePageContentService<HomePageQuery> {

  protected abstract getEntityType(): EntityTypeEnum;

  public abstract getBreadcrumbLinkLabel(query?: HomePageQuery): string;

  public abstract getBreadcrumbLinkTitle(query?: HomePageQuery): string;

  public abstract getBreadcrumbSchemaName(query?: HomePageQuery): string;

  private homePageUrlQueryMapper;

  constructor(
    content: BasePageContentModel,
    websiteOrigin: string,
    protected readonly location: LocationListItemWithParentApiModel | LocationDetailWithGeoApiModel | undefined
  ) {
    super(content, websiteOrigin);

    this.homePageUrlQueryMapper = HomePageUrlQueryMapper.factory();
  }

  public getCanonicalUrl(query?: HomePageQuery): string {
    const parts: string[] = [
      this.origin,
      createPath(this.getSeoUrl(query)),
    ];

    return parts.join('');
  }

  public getSeoUrl(query?: HomePageQuery): Path {
    const homePageQuery: HomePageQuery = {
      tab: this.getEntityType(),
      location: query?.location ? { ...query.location } : undefined,
    };

    return this.homePageUrlQueryMapper.generateHomePageFriendlyUrl(homePageQuery);
  }

  public createPageTitle(query: HomePageQuery): string {
    let title = this.getBreadcrumbLinkTitle(query);

    if (this.location) {
      if (this.location.parent && this.location.parent.parent) {
        title += ' - ' + this.location.name + ', ' + this.location.parent.name;
      } else {
        title += ' ' + this.location.name;
      }
    }

    return title;
  }
}

export default EntitiesListPageContentService;
