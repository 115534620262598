import BaseApplicationError from './BaseApplicationError';

abstract class BaseMessageError extends BaseApplicationError {

  public payload: string;

  constructor(message: string) {
    super(message);

    this.payload = message;
  }
}

export default BaseMessageError;
