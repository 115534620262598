type SchemaTypeOptions = 'AnimalShelter' | 'Article' | 'BreadcrumbList' | 'Place' | 'PostalAddress' | 'VeterinaryCare' | 'ImageObject' | 'Person';

abstract class BaseSchemaContext {

  readonly '@context' = "https://schema.org";

  readonly '@type': SchemaTypeOptions;

  constructor(type: SchemaTypeOptions) {
    this['@type'] = type;
  }
}

export type {
  SchemaTypeOptions
};

export default BaseSchemaContext;
