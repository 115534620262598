import React, { type PropsWithChildren, type ReactElement } from 'react';
import type BasePageContentService from '../../../services/content/abstract/BasePageContentService';
import PageMetadata from '../../shared/elements/PageMetadata';

interface EntityPageMetadataProps<
  TService extends BasePageContentService<TServiceParams>,
  TServiceParams
> {
  pageContentService: TService;
  pageContentServiceParams?: TServiceParams | undefined;
}

const EntityPageMetadata: <
  TService extends BasePageContentService<TServiceParams>,
  TServiceParams
>({
  children,
  pageContentService,
  pageContentServiceParams,
}: PropsWithChildren<EntityPageMetadataProps<TService, TServiceParams>>) => ReactElement<EntityPageMetadataProps<TService, TServiceParams>> = <
  TIntrinsicService extends BasePageContentService<TIntrinsicServiceParams>,
  TIntrinsicServiceParams
>({
  children,
  pageContentService,
  pageContentServiceParams,
}: PropsWithChildren<EntityPageMetadataProps<TIntrinsicService, TIntrinsicServiceParams>>) => {
    return (
      <PageMetadata
        title={pageContentService.getHeadTitle(pageContentServiceParams)}
        metaDescription={pageContentService.getHeadMetaDescription(pageContentServiceParams)}
      >
        <link rel='canonical' href={pageContentService.getCanonicalUrl(pageContentServiceParams)} />
        {children !== undefined ? children : null}
      </PageMetadata>
    );
  };

export default EntityPageMetadata;
