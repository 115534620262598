import React, { type PropsWithChildren } from 'react';
import { Link } from 'react-router-dom';
import styles from './LinkAccentPrimary.module.scss';
import type { LinkAccentProps } from './base/LinkAccentProps';

const LinkAccentPrimary: React.FC<PropsWithChildren<LinkAccentProps>> = ({ to, children }: PropsWithChildren<LinkAccentProps>) => {
  return (
    <Link
      to={to}
      className={styles['app-link']}
    >
      {children}
    </Link>
  );
};

export default LinkAccentPrimary;
