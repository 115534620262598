import type { Action, Reducer } from 'redux';
import type { FSAP } from '../../../../../framework/base/common/store/actions/base/fsa';
import type { BaseSucceededPayload } from '../../../../../framework/base/common/store/actions/models/payloads/base/BaseSucceededPayload';
import fetchByQueryReducerFactory from '../../../../../framework/base/common/store/modules/base/fetchByQuery/fetchByQueryReducerFactory';
import type { BaseHomeEntitiesDataModel } from '../../../../models/home/base/BaseHomeEntitiesDataModel';
import type { HomePageQuery } from '../../../../models/system/home/queries/HomePageQuery';
import { actionTypeSucceededPage } from './baseHomeEntitiesActionCreatorsFactory';
import type { BaseHomeEntitiesState } from './models/BaseHomeEntitiesState';

function baseHomeEntitiesReducerFactory<
  TState extends BaseHomeEntitiesState<THomeEntitiesDataModel, TEntityListItemHomePageApiModel>,
  THomeEntitiesDataModel extends BaseHomeEntitiesDataModel<TEntityListItemHomePageApiModel>,
  TEntityListItemHomePageApiModel,
>(
  namespace: string,
  emptyState: TState,
): Reducer<
  TState,
  Action
> {

  const succeedPageCaseReducer = (state: TState | undefined, action: FSAP<BaseSucceededPayload<THomeEntitiesDataModel>>): TState => {
    const actionData = action.payload.data;

    return {
      ...(state ?? emptyState),
      data: {
        ...state?.data,
        entities: {
          ...state?.data?.entities,
          count: actionData.entities.count,
          items: [
            ...state?.data?.entities?.items ?? [],
            ...actionData.entities.items,
          ]
        }
      },
    };
  };

  const reducer: Reducer<
    TState,
    Action
  > = (
    state: TState | undefined,
    action: Action
  ): TState => {
      if (action.type.startsWith(`${namespace}/`)) {
        switch (action.type) {
          case `${namespace}/${actionTypeSucceededPage}`:
            return succeedPageCaseReducer(state, action as FSAP<BaseSucceededPayload<THomeEntitiesDataModel>>);
          default:
            return fetchByQueryReducerFactory<TState, THomeEntitiesDataModel, HomePageQuery>(namespace, emptyState)(state, action);
        }
      }

      return state ?? emptyState;
    };

  return reducer;
}

export default baseHomeEntitiesReducerFactory;
