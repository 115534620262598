import type { FSAP } from '../../actions/base/fsa';
import { clearMessages as clearMessagesAction, setApplicationErrorMessage as setApplicationErrorMessageAction, setErrorMessage as setErrorMessageAction, setMessage as setMessageAction, type BaseMessagePayload, type SetErrorMessagePayload, type SetMessagePayload } from '../app/notificationsReducer';

// Action types

type SetMessageAction = FSAP<SetMessagePayload>;

type SetErrorMessageAction = FSAP<SetErrorMessagePayload>;

// Action creators

export const setMessage = (message: string, options?: BaseMessagePayload): SetMessageAction => {
  return setMessageAction({
    ...options,
    message,
  });
};

export const setErrorMessage = (errorMessage: string, options?: BaseMessagePayload): SetErrorMessageAction => {
  return setErrorMessageAction({
    ...options,
    errorMessage,
  });
};

export const setApplicationErrorMessage = setApplicationErrorMessageAction;

export const clearMessages = clearMessagesAction;
