import type BaseHomeEntitiesService from '../../../../../client/services/home/base/BaseHomeEntitiesService';
import ApplicationError from '../../../../../framework/base/common/models/errors/ApplicationError';
import BaseApplicationError from '../../../../../framework/base/common/models/errors/base/BaseApplicationError';
import type { FSAP } from '../../../../../framework/base/common/store/actions/base/fsa';
import type { BaseSucceededPayload } from '../../../../../framework/base/common/store/actions/models/payloads/base/BaseSucceededPayload';
import type { CreateThunkAction } from '../../../../../framework/base/common/store/modules/base/fetch/dataActionCreators';
import fetchByQueryAsyncActionCreatorsFactory, { type FetchByQueryAsyncActionCreators } from '../../../../../framework/base/common/store/modules/base/fetchByQuery/fetchByQueryAsyncActionCreatorsFactory';
import type { HomePageQuery } from '../../../../models/system/home/queries/HomePageQuery';

const actionTypeSucceededPage = 'succeeded-page';

interface BaseHomeEntitiesActionCreators<TData> extends FetchByQueryAsyncActionCreators<TData, HomePageQuery> {
  fetchPageByQueryAsync: (query: HomePageQuery) => CreateThunkAction<TData, HomePageQuery>;
}

function baseHomeEntitiesActionCreatorsFactory<TData>(
  namespace: string,
  serviceCtor: new () => BaseHomeEntitiesService<TData>,
): BaseHomeEntitiesActionCreators<TData> {

  const fetchByQueryAsyncActionCreators = fetchByQueryAsyncActionCreatorsFactory(
    namespace,
    async (query: HomePageQuery) => await (new serviceCtor()).search(query)
  );

  const succeedPage = (data: TData): FSAP<BaseSucceededPayload<TData>> => ({
    type: `${namespace}/${actionTypeSucceededPage}`,
    payload: {
      data,
    },
  });

  const fetchPageByQueryAsync = (query: HomePageQuery): CreateThunkAction<TData, HomePageQuery> => async (dispatch) => {
    dispatch(fetchByQueryAsyncActionCreators.fetchByQuery(query));

    try {
      const service = new serviceCtor();
      const result = await service.more(query);

      dispatch(succeedPage(result));
    } catch (error) {
      if (error instanceof BaseApplicationError) {
        dispatch(fetchByQueryAsyncActionCreators.failed(error));
      } else {
        dispatch(fetchByQueryAsyncActionCreators.failed(new ApplicationError()));
      }
    }
  };

  const result: BaseHomeEntitiesActionCreators<TData> = {
    ...fetchByQueryAsyncActionCreators,
    fetchPageByQueryAsync: fetchPageByQueryAsync,
  };

  return result;
}

export {
  actionTypeSucceededPage
};

export default baseHomeEntitiesActionCreatorsFactory;
