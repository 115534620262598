import type { DeepReadonly } from '../../../../../framework/base/common/constants/types/UtilityTypes';
import BreadcrumbListSchema from '../../../../../framework/base/common/models/system/schema/BreadcrumbListSchema';
import ListItemSchema from '../../../../../framework/base/common/models/system/schema/ListItemSchema';
import type { BreadcrumbData } from '../../../../../framework/base/common/services/content/breadcrumbs/models/BreadcrumbData';
import type { BreadcrumbLinkData } from '../../../../../framework/base/common/services/content/breadcrumbs/models/BreadcrumbLinkData';
import type { BreadcrumbSchemaData } from '../../../../../framework/base/common/services/content/breadcrumbs/models/BreadcrumbSchemaData';
import type { LocationDetailWithGeoApiModel } from '../../../../../framework/gis/common/models/api/location/LocationDetailWithGeoApiModel';
import type { LocationListItemWithParentApiModel } from '../../../../../framework/gis/common/models/api/location/LocationListItemWithParentApiModel';
import type { LocationSharedPropertiesApiModel } from '../../../../../framework/gis/common/models/api/location/LocationSharedPropertiesApiModel';
import HomePageUrlQueryMapper from '../../../../helpers/mappers/HomePageUrlQueryMapper';
import type { HomePageQuery } from '../../../../models/system/home/queries/HomePageQuery';
import type { HomePageQueryFilters } from '../../../../models/system/home/queries/HomePageQueryFilters';
import type EntitiesListPageContentService from '../../abstract/list/EntitiesListPageContentService';
import type { BreadcrumbSpec } from '../models/BreadcrumbSpec';

type BreadcrumbLinkDataUrl = Pick<BreadcrumbLinkData, 'linkUrl' | 'linkSeoUrl'>;

type BreadcrumbSchemaDataUrl = Pick<BreadcrumbSchemaData, 'schemaUrl'>;

const getLocations = (location: LocationListItemWithParentApiModel | LocationDetailWithGeoApiModel): (LocationListItemWithParentApiModel | LocationDetailWithGeoApiModel)[] => {
  const parents = location.parent
    ? getLocations(location.parent)
    : [];
  return [
    ...parents,
    location,
  ];
};

abstract class EntitiesListBreadcrumbsService {

  public static createBreadcrumbsSchema(breadcrumbs: BreadcrumbData[]): BreadcrumbListSchema {
    const schema = new BreadcrumbListSchema();

    for (const b of breadcrumbs) {
      const homeItem = new ListItemSchema();

      homeItem.name = b.schema.schemaName;
      homeItem.item = b.schema.schemaUrl;

      schema.push(homeItem);
    }

    return schema;
  }

  public static createTaxonomyHomePageQueries(linksSpec: BreadcrumbSpec[], query: HomePageQuery): HomePageQuery[] {
    const result: HomePageQuery[] = [];

    for (const linkSpec of linksSpec) {
      if (!linkSpec.requiredTaxonomy || query.filters?.[linkSpec.requiredTaxonomy]?.length === 1) {
        const linkFilters: HomePageQueryFilters = {
          ...query.filters,
          ...linkSpec.clearTaxonomy,
        };

        const linkQuery: HomePageQuery = {
          ...query,
          filters: linkFilters,
          location: undefined,
        };

        result.push(linkQuery);
      }
    }

    return result;
  }

  protected readonly origin: string = process.env['CLIENT_BUILD_WEBSITE_ORIGIN'] || '';

  constructor(private contentService: EntitiesListPageContentService) { }

  public createBreadcrumbsForList(query: DeepReadonly<HomePageQuery>, locationData: LocationListItemWithParentApiModel | LocationDetailWithGeoApiModel | undefined): BreadcrumbData[] {
    const cateogry = this.createCateogryBreadcrumbs(query);
    const location = this.createLocationBreadcrumbs(query, locationData, cateogry[cateogry.length - 1]?.link?.linkTitle);
    const result: BreadcrumbData[] = [
      ...cateogry,
      ...location,
    ];

    return result;
  }

  protected getLocationDepthLimit() {
    return 7;
  }

  // Virtual
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  protected createCateogryBreadcrumbs(_query: DeepReadonly<HomePageQuery>): BreadcrumbData[] {
    const result: BreadcrumbData[] = [];

    return result;
  }

  private createLocationBreadcrumbs(
    query: DeepReadonly<HomePageQuery>,
    locationData: LocationListItemWithParentApiModel | LocationDetailWithGeoApiModel | undefined,
    titlePrefix: string | undefined,
  ): BreadcrumbData[] {
    if (!locationData) {
      return [];
    }

    const result: BreadcrumbData[] = [];

    const locations = getLocations(locationData).slice(0, this.getLocationDepthLimit());

    let previousSlugs: string[] = [];

    for (const location of locations) {
      const slugs = [
        ...previousSlugs,
        location.localNameSlug,
      ];

      const locationQuery: DeepReadonly<HomePageQuery> = {
        ...query,
        location: {
          id: location.id,
          slugs: slugs,
        },
      };

      const breadcrumbLinkData = this.createLocationBreadcrumbLinkData(locationQuery, location, titlePrefix);
      const breadcrumbSchemaData = this.createLocationBreadcrumbsSchemaData(locationQuery, location);

      const breadcrumbData: BreadcrumbData = {
        link: breadcrumbLinkData,
        schema: breadcrumbSchemaData,
      };

      result.push(breadcrumbData);

      previousSlugs = [
        ...slugs,
      ];
    }

    return result;
  }

  public createMainBreadcrumbSchemaData(query: HomePageQuery): BreadcrumbSchemaData {
    const schemaName = this.contentService.getBreadcrumbSchemaName(query);

    const breadcrumbData: BreadcrumbSchemaData = {
      schemaName,
      ...this.createBreadcrumbSchemaDataUrls(query),
    };

    return breadcrumbData;
  }

  public createMainBreadcrumbLinkData(
    query: HomePageQuery,
  ): BreadcrumbLinkData {
    const linkLabel = this.contentService.getBreadcrumbLinkLabel(query);
    const linkTitle = this.contentService.getBreadcrumbLinkTitle(query);

    const breadcrumbData: BreadcrumbLinkData = {
      linkLabel,
      linkTitle,
      ...this.createBreadcrumbLinkDataUrls(query),
    };

    return breadcrumbData;
  }

  private createLocationBreadcrumbsSchemaData(query: DeepReadonly<HomePageQuery>, location: LocationSharedPropertiesApiModel): BreadcrumbSchemaData {
    const schemaName = location.name;

    const breadcrumbData: BreadcrumbSchemaData = {
      schemaName,
      ...this.createBreadcrumbSchemaDataUrls(query),
    };

    return breadcrumbData;
  }

  public createLocationBreadcrumbLinkData(query: DeepReadonly<HomePageQuery>, location: LocationSharedPropertiesApiModel, linkTitleLocationPrefix: string | undefined): BreadcrumbLinkData {
    const linkLabel = location.name;
    const linkTitle = linkTitleLocationPrefix ? `${linkTitleLocationPrefix} ${location.name}` : location.name;

    const breadcrumbData: BreadcrumbLinkData = {
      linkLabel,
      linkTitle,
      ...this.createBreadcrumbLinkDataUrls(query),
    };

    return breadcrumbData;
  }

  private createBreadcrumbLinkDataUrls(query: DeepReadonly<HomePageQuery>): BreadcrumbLinkDataUrl {
    const linkUrl = HomePageUrlQueryMapper.factory().generateHomePageFriendlyUrl(query);
    const linkSeoUrl = this.contentService.getSeoUrl(query);

    const breadcrumbData: BreadcrumbLinkDataUrl = {
      linkUrl,
      linkSeoUrl,
    };

    return breadcrumbData;
  }

  private createBreadcrumbSchemaDataUrls(query: DeepReadonly<HomePageQuery>): BreadcrumbSchemaDataUrl {
    const schemaUrl = this.contentService.getCanonicalUrl(query);

    const breadcrumbData: BreadcrumbSchemaDataUrl = {
      schemaUrl,
    };

    return breadcrumbData;
  }
}

export {
  getLocations
};

export default EntitiesListBreadcrumbsService;
