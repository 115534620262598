import React from 'react';
import SeoLink from '../../../../../framework/base/common/components/shared/elements/SeoLink';
import { arrayHelperFilter } from '../../../../../framework/base/common/helpers/array/arrayHelperFilter';
import type { BreadcrumbData } from '../../../../../framework/base/common/services/content/breadcrumbs/models/BreadcrumbData';
import type { HomePageQuery } from '../../../../models/system/home/queries/HomePageQuery';
import type EntitiesListPageContentService from '../../../../services/content/abstract/list/EntitiesListPageContentService';
import styles from './HomePageEntitiesListHeader.module.scss';

interface HomePageEntitiesListHeaderProps {
  entitiesListPageContentService: EntitiesListPageContentService;
  query: HomePageQuery;
  breadcrumbs: BreadcrumbData[],
}

// let renderCounter = 0;

const HomePageEntitiesListHeader: React.FC<HomePageEntitiesListHeaderProps> = ({ entitiesListPageContentService, query, breadcrumbs }: HomePageEntitiesListHeaderProps) => {

  // console.debug(`Render HomePageEntitiesListHeader ${++renderCounter}`);

  const breadcrumbLinks = arrayHelperFilter(breadcrumbs.map((breadcrumb) => breadcrumb.link));

  return (
    <header>
      <h1 className={styles['app-h1']}>
        {entitiesListPageContentService.createPageTitle(query)}
      </h1>
      {breadcrumbLinks.length > 1 && (
        <nav className={styles['app-nav']}>
          <ol className={styles['app-breadcrumbs']}>
            {([...breadcrumbLinks].reverse()).map((breadcrumbData, index) => (
              <li key={index} className={styles['app-breadcrumb-item']}>
                {breadcrumbData.linkSeoUrl && index !== 0
                  ? (
                    <SeoLink
                      seoTo={breadcrumbData.linkSeoUrl}
                      uiTo={breadcrumbData.linkUrl}
                      title={breadcrumbData.linkTitle}
                    >
                      {breadcrumbData.linkLabel}
                    </SeoLink>
                  )
                  : (
                    <span>
                      {breadcrumbData.linkLabel}
                    </span>
                  )}
              </li>
            ))}
          </ol>
        </nav>
      )}
    </header>
  );
};

export default HomePageEntitiesListHeader;
