import React, { type PropsWithChildren } from 'react';
import type { IModule } from '../../../store/IStoreModule';
import DynamicModuleBasic from './DynamicModuleBasic';

interface DynamicModuleLoaderWrapperProps {
  modules?: IModule<unknown>[] | undefined;
  module?: IModule<unknown> | undefined;
}

// let renderCounter = 0;

const DynamicModuleLoaderWrapper: React.FC<PropsWithChildren<DynamicModuleLoaderWrapperProps>> = ({
  modules,
  module,
  children,
}: PropsWithChildren<DynamicModuleLoaderWrapperProps>) => {

  // console.debug(`Render DynamicModuleLoaderWrapper ${++renderCounter}`, modules);

  return (
    <DynamicModuleBasic
      modules={modules}
      module={module}
    >
      {children}
    </DynamicModuleBasic>
  );
};

export default DynamicModuleLoaderWrapper;
