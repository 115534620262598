function notEmpty<TValue>(value: TValue | null | undefined): value is TValue {
  if (value === null || value === undefined) {
    throw new Error('Array value cannot be empty or undefined');
  }

  return true;
}

function arrayHelperFilter<T>(array: (T | undefined)[]): T[] {
  return array.filter(notEmpty);
}

export {
  arrayHelperFilter
};

