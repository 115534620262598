import React, { useCallback, useEffect, useState, type PropsWithChildren } from 'react';
import { useStore } from 'react-redux';
import type { IModule } from '../../../store/IStoreModule';
import type { IModuleStore } from '../../../store/factories/appStoreFactory';
import type { IAppAwareState } from '../../../store/modules/app/models/IAppAwareState';

interface DynamicModuleCleanupProps {
  cleanup: () => void;
}

const DynamicModuleCleanup: React.FC<DynamicModuleCleanupProps> = ({
  cleanup,
}: DynamicModuleCleanupProps) => {

  useEffect(() => {
    return () => {
      cleanup();
    };
  }, [cleanup]);

  return null;
};

interface DynamicModuleBasicProps {
  modules?: IModule<unknown>[] | undefined;
  module?: IModule<unknown> | undefined;
}

// let renderCounter = 0;

const DynamicModuleBasic: React.FC<PropsWithChildren<DynamicModuleBasicProps>> = ({
  modules,
  module,
  children,
}: PropsWithChildren<DynamicModuleBasicProps>) => {

  // console.debug(`Render DynamicModuleBasic ${++renderCounter}`, modules);

  const store = useStore() as unknown as IModuleStore<IAppAwareState>; // TODO

  const isModuleAdded = modules
    ? modules.every((module) => store.isModuleAdded(module))
    : module
      ? store.isModuleAdded(module)
      : false;

  const [isReadyToRender, setIsReadyToRender] = useState(isModuleAdded);

  const cleanup = useCallback(() => {
    if (isReadyToRender) {
      if (modules) {
        store.removeModules(modules);
      }
      if (module) {
        store.removeModule(module);
      }
    }
  }, [module, modules, isReadyToRender, store]);

  useEffect(() => {
    if (!isReadyToRender) {
      if (modules) {
        store.addModules(modules);
        setIsReadyToRender(true);
      }
    }
  }, [modules, isReadyToRender, store]);

  useEffect(() => {
    if (!isReadyToRender) {
      if (module) {
        store.addModule(module);
        setIsReadyToRender(true);
      }
    }
  }, [module, isReadyToRender, store]);

  return isReadyToRender
    ? (
      <>
        {children}
        <DynamicModuleCleanup cleanup={cleanup} />
      </>
    )
    : null;
};

export default DynamicModuleBasic;
