import type { ItemsListModel } from '../../../models/system/pagination/ItemsListModel';
import type { EntitiesListItemsListSelector } from '../EntitiesListItemsListSelector';

const createEntitiesListItemsListSelector = <
  TListItemlModel,
  TState,
>
  (
    getEntitiesItemsItemsListState: (state: TState) => ItemsListModel<TListItemlModel> | undefined
  ): EntitiesListItemsListSelector<TListItemlModel, TState> => {
  return {
    data: (state: TState): TListItemlModel[] | undefined => getEntitiesItemsItemsListState(state)?.items,
    getCount: (state: TState): number => getEntitiesItemsItemsListState(state)?.count ?? 0,
  };
};

export default createEntitiesListItemsListSelector;
