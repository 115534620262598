import type { Action, Reducer } from 'redux';
import { actionTypeFetchByQuery } from '../../../actions/actionTypes';
import type { FSAP } from '../../../actions/base/fsa';
import type { BaseItemQueryPayload } from '../../../actions/models/payloads/base/BaseItemQueryPayload';
import type { BaseQueryDataState, BaseQueryDataStateActionQuery } from '../../../actions/models/state/common/BaseQueryDataState';
import fetchReducerFactory from '../fetch/fetchReducerFactory';

function fetchByQueryCaseReducer<
  TState extends BaseQueryDataStateActionQuery<TQuery>,
  TQuery
>(state: Readonly<TState>, action: Readonly<FSAP<BaseItemQueryPayload<TQuery>>>): TState {
  return {
    ...state,
    isFetching: true,
    query: action.payload.query,
  };
}

function fetchByQueryReducerFactory<
  TState extends BaseQueryDataState<TData, TQuery>,
  TData,
  TQuery,
>(
  namespace: string,
  emptyState: TState,
): Reducer<
  TState,
  Action
> {

  const reducer: Reducer<
    TState,
    Action
  > = (
    state: TState | undefined,
    action: Action
  ): TState => {
      if (action.type.startsWith(`${namespace}/`)) {
        switch (action.type) {
          case `${namespace}/${actionTypeFetchByQuery}`:
            return fetchByQueryCaseReducer<TState, TQuery>(state ?? emptyState, action as unknown as FSAP<BaseItemQueryPayload<TQuery>>);
          default:
            return fetchReducerFactory(namespace, emptyState)(state, action);
        }
      }

      return state ?? emptyState;
    };

  return reducer;
}

export default fetchByQueryReducerFactory;
