import React, { type PropsWithChildren } from 'react';
import { Helmet } from 'react-helmet';

interface PageMetadataProps {
  title: string;
  metaDescription?: string;
}

const PageMetadata: React.FC<PropsWithChildren<PageMetadataProps>> = ({ children, title, metaDescription }: PropsWithChildren<PageMetadataProps>) => {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={metaDescription ?? title} />
      {children !== undefined ? children : null}
    </Helmet>
  );
};

export default PageMetadata;
