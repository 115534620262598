import type { LoadableComponent } from '@loadable/component';
import React, { useCallback, useEffect, useMemo, type ReactElement } from 'react';
import { useSelector } from 'react-redux';
import Spinner from '../../../../../framework/base/common/components/shared/elements/Spinner';
import LinkAccentPrimary from '../../../../../framework/base/common/components/shared/partials/links/LinkAccentPrimary';
import type { DeepReadonly } from '../../../../../framework/base/common/constants/types/UtilityTypes';
import useErrorHandler from '../../../../../framework/base/common/hooks/useErrorHandler';
import type BaseApplicationError from '../../../../../framework/base/common/models/errors/base/BaseApplicationError';
import type { EntitiesListItemsListSelector } from '../../../../../framework/base/common/store/selectors/EntitiesListItemsListSelector';
import type { IHomePageUrlGenerator } from '../../../../../framework/gis/common/hooks/IHomePageUrlGenerator';
import type { BasePoiEntityListItemHomePageApiModel } from '../../../../../framework/gis/common/models/api/base/poi/specified/BasePoiEntityListItemHomePageApiModel';
import EntityTypeEnum from '../../../../constants/enums/EntityTypeEnum';
import type { HomePageQuery } from '../../../../models/system/home/queries/HomePageQuery';
import HomePageEntitiesListScrollUp from './HomePageEntitiesListScrollUp';

interface BaseHomePageEntitiesListNoItemsComponentProps {
  urlGenerator: IHomePageUrlGenerator;
}

interface HomePageEntitiesListNoItemsComponentProps<
  TState
> extends BaseHomePageEntitiesListNoItemsComponentProps {
  querySelector: (state: TState) => HomePageQuery | undefined;
}

interface HomePageEntitiesListProps<
  TListItemlModel extends BasePoiEntityListItemHomePageApiModel,
  TState
> {
  selectors: EntitiesListItemsListSelector<TListItemlModel, TState>,
  selectorError: (state: TState) => BaseApplicationError | undefined,
  querySelector: (state: TState) => HomePageQuery | undefined;
  urlGenerator: IHomePageUrlGenerator;
  listClassName?: string | undefined,
  render: (
    item: TListItemlModel,
    locationSearch: string,
    index: number,
    isActive: boolean,
    handleOnClickPin: (event: React.MouseEvent<HTMLButtonElement>) => void,
  ) => ReactElement;
  noItemsComponent: LoadableComponent<HomePageEntitiesListNoItemsComponentProps<TState>>;
}

const HomePageEntitiesList: <
  TListItemlModel extends BasePoiEntityListItemHomePageApiModel,
  TState
>(props: HomePageEntitiesListProps<TListItemlModel, TState>)
  => ReactElement<HomePageEntitiesListProps<TListItemlModel, TState>> | null = <
    TListItemlModelIntrinsic extends BasePoiEntityListItemHomePageApiModel,
    TStateIntrinsic
  >({
    selectors,
    selectorError,
    querySelector,
    urlGenerator,
    listClassName,
    render,
    noItemsComponent,
  }: HomePageEntitiesListProps<TListItemlModelIntrinsic, TStateIntrinsic>) => {

    const NoItemsComponent = noItemsComponent;

    const items = useSelector(selectors.data);
    const count = useSelector(selectors.getCount);
    const error = useSelector(selectorError);

    const errorHandler = useErrorHandler();

    const handleOnClickPin = useCallback((item: TListItemlModelIntrinsic, event: React.MouseEvent<HTMLButtonElement>): void => {
      event.stopPropagation();

      urlGenerator.redirectHomePageNoImmer((draft: DeepReadonly<HomePageQuery>) => {
        const isActive = draft.activePin === item.h && draft.active === item.i;

        const result: DeepReadonly<HomePageQuery> = {
          ...draft,
          activePin: isActive
            ? undefined
            : item.h,
          active: isActive
            ? undefined
            : item.i
        };

        return result;
      }, true);
    }, [urlGenerator]);

    const currentListSearchQuery = useMemo(() => {
      return urlGenerator.generateHomePageUrlNoImmer((draft) => ({
        ...draft,
        tab: EntityTypeEnum.Advert,
      }), false).search;
    }, [urlGenerator]);

    useEffect(() => {
      if (error) {
        errorHandler(error);
      }
    }, [error, errorHandler]);

    return !error
      ? (
        <>
          {items === undefined && (
            <div className='d-flex flex-column text-center my-5 py-5 app-loading-adverts'>
              <Spinner className='m-auto text-muted' />
              <p className='mt-3 mb-auto text-muted'>Wczytywanie ogłoszeń...</p>
            </div>
          )}
          {items !== undefined && (
            <>
              {items.length === 0 && (
                <NoItemsComponent
                  urlGenerator={urlGenerator}
                  querySelector={querySelector}
                />
              )}
              {items.length > 0 && (
                <>
                  <ul className={listClassName}>
                    {items.map((item, index) => (
                      <React.Fragment
                        key={item.i}
                      >
                        {render(
                          item,
                          currentListSearchQuery,
                          index,
                          urlGenerator.query.active === item.i,
                          (event: React.MouseEvent<HTMLButtonElement>) => handleOnClickPin(item, event)
                        )}
                      </React.Fragment>
                    ))}
                  </ul>
                  <HomePageEntitiesListScrollUp />
                  {count > items.length && (
                    <div className='load-more text-center my-3'>
                      <LinkAccentPrimary
                        to={urlGenerator.generateHomePageUrlNoImmer((draft) => {
                          const result: DeepReadonly<HomePageQuery> = {
                            ...draft,
                            pagination: {
                              ...draft.pagination,
                              page: (draft.pagination?.page ?? 0) + 1
                            }
                          };

                          return result;
                        })}
                      >
                        Pokaż kolejne
                      </LinkAccentPrimary>
                    </div>
                  )}
                </>
              )}
            </>
          )}
        </>
      )
      : null;
  };

export type {
  BaseHomePageEntitiesListNoItemsComponentProps,
  HomePageEntitiesListNoItemsComponentProps
};

export default HomePageEntitiesList;
