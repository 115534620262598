import BaseSchemaContext from './base/BaseSchemaContext';
import type ListItemSchema from './ListItemSchema';

class BreadcrumbListSchema extends BaseSchemaContext {

  readonly name = 'Breadcrumbs';

  readonly itemListElement: ListItemSchema[] = [];

  constructor() {
    super('BreadcrumbList');
  }

  public push = (listItem: ListItemSchema): void => {
    listItem.position = this.itemListElement.length + 1;

    this.itemListElement.push(listItem);
  };
}

export default BreadcrumbListSchema;
