import BaseEntitiesQueryMapper from '../../../../../base/common/helpers/mappers/base/BaseEntitiesQueryMapper';
import type { BaseLatLngUsersEntitiesApiQuery } from '../../../models/queries/base/BaseLatLngUsersEntitiesApiQuery';
import type { BaseLatLngUsersEntitiesApiQueryFilters } from '../../../models/queries/base/BaseLatLngUsersEntitiesApiQueryFilters';
import type { BaseLatLngUsersEntitiesQuery } from '../../../models/queries/base/BaseLatLngUsersEntitiesQuery';
import type { BaseLatLngUsersEntitiesQueryFilters } from '../../../models/queries/base/BaseLatLngUsersEntitiesQueryFilters';
import type { MapParamsApiQuery } from '../../../models/system/map/queries/MapParamsApiQuery';

abstract class BaseLatLngUsersEntitiesQueryMapper<
  TQueryFilters extends BaseLatLngUsersEntitiesQueryFilters = BaseLatLngUsersEntitiesQueryFilters,
  TQuery extends BaseLatLngUsersEntitiesQuery<TQueryFilters> = BaseLatLngUsersEntitiesQuery<TQueryFilters>,
  TApiQueryFilters extends BaseLatLngUsersEntitiesApiQueryFilters = BaseLatLngUsersEntitiesApiQueryFilters,
  TApiQuery extends BaseLatLngUsersEntitiesApiQuery<TApiQueryFilters> = BaseLatLngUsersEntitiesApiQuery<TApiQueryFilters>
> extends BaseEntitiesQueryMapper<
  TQueryFilters,
  TQuery,
  TApiQueryFilters,
  TApiQuery
> {

  protected override mapQueryToApiQueryIntrinsic(query: TQuery): BaseLatLngUsersEntitiesApiQuery<TApiQueryFilters> {
    const apiQuery: BaseLatLngUsersEntitiesApiQuery<TApiQueryFilters> = super.mapQueryToApiQueryIntrinsic(query);

    const map = this.mapQueryToApiQueryMap(query);

    if (map) {
      apiQuery.map = map;
    }

    if (query.location?.id) {
      apiQuery.locationId = query.location.id;
    }

    if (query.location?.slugs) {
      apiQuery.locationSlugs = query.location.slugs;
    }

    return apiQuery;
  }

  protected override mapQueryFiltersToApiQueryFiltersIntrinsic(queryFilters: TQueryFilters): BaseLatLngUsersEntitiesApiQueryFilters {
    const apiQueryFilters: BaseLatLngUsersEntitiesApiQueryFilters = super.mapQueryFiltersToApiQueryFiltersIntrinsic(queryFilters);

    apiQueryFilters.title = queryFilters.title;

    return apiQueryFilters;
  }

  private mapQueryToApiQueryMap(query: TQuery): MapParamsApiQuery | undefined {
    if (query.map?.bounds) {
      const map: MapParamsApiQuery = {
        bounds: {
          ne: {
            lat: query.map?.bounds.ne.lat.toString(),
            lng: query.map?.bounds.ne.lng.toString(),
          },
          sw: {
            lat: query.map?.bounds.sw.lat.toString(),
            lng: query.map?.bounds.sw.lng.toString(),
          },
        }
      };

      return map;
    }

    return undefined;
  }
}

export default BaseLatLngUsersEntitiesQueryMapper;
