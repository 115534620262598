interface BasePageContentModel {
  headTitle: string;
  headMetaDescription: string;
  pageHeader: string;
}

abstract class BasePageContentService<TParams> {

  protected origin: string;

  private content: BasePageContentModel;

  public abstract getCanonicalUrl(params?: TParams | undefined): string;

  constructor(content: BasePageContentModel, websiteOrigin: string) {
    this.content = content;
    this.origin = websiteOrigin;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public getHeadTitle(_params?: TParams): string {
    return this.content.headTitle;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public getHeadMetaDescription(_params?: TParams): string {
    return this.content.headMetaDescription;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public getPageHeader = (_params?: TParams): string => {
    return this.content.pageHeader;
  };
}

export type {
  BasePageContentModel
};

export default BasePageContentService;
