import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import NotFound404Error from '../../client/models/errors/NotFound404Error';
import { APPLICATION_ERROR_MESSAGE } from '../helpers/BaseContent';
import ErrorMessageError from '../models/errors/ErrorMessageError';
import InfoMessageError from '../models/errors/InfoMessageError';
import BaseApplicationError from '../models/errors/base/BaseApplicationError';
import { setInfoMessage } from '../store/modules/app/notificationsReducer';
import { setApplicationErrorMessage, setErrorMessage } from '../store/modules/messages/messagesActions';

interface ErrorHandlerConfig {
  notFound404Message?: string | undefined;
}

const useErrorHandler = (config?: ErrorHandlerConfig): (error: unknown) => void => {
  const dispatch = useDispatch();

  const errorHandler = useCallback((error: unknown): void => {
    console.error(error);

    if (error instanceof NotFound404Error) {
      dispatch(setErrorMessage(config?.notFound404Message ?? APPLICATION_ERROR_MESSAGE));
    } else if (error instanceof ErrorMessageError) {
      dispatch(setErrorMessage(error.payload));
    } else if (error instanceof InfoMessageError) {
      dispatch(setInfoMessage({ infoMessage: error.payload }));
    } else if (error instanceof BaseApplicationError) {
      dispatch(setApplicationErrorMessage());
    } else {
      dispatch(setApplicationErrorMessage());
    }
  }, [config?.notFound404Message, dispatch]);

  return errorHandler;
};

export default useErrorHandler;
