import BaseLatLngUsersEntitiesQueryMapper from '../../../framework/gis/common/helpers/mappers/base/BaseLatLngUsersEntitiesQueryMapper';
import type { HomePageApiQuery } from '../../models/system/home/queries/HomePageApiQuery';
import type { HomePageApiQueryFilters } from '../../models/system/home/queries/HomePageApiQueryFilters';
import type { HomePageQuery } from '../../models/system/home/queries/HomePageQuery';
import type { HomePageQueryFilters } from '../../models/system/home/queries/HomePageQueryFilters';

class HomePageQueryMapper extends BaseLatLngUsersEntitiesQueryMapper<
  HomePageQueryFilters,
  HomePageQuery,
  HomePageApiQueryFilters,
  HomePageApiQuery
> {

  public override mapQueryToApiQuery(query: HomePageQuery): HomePageApiQuery {
    const apiQuery: HomePageApiQuery = super.mapQueryToApiQueryIntrinsic(query);

    if (query.tab) {
      apiQuery.tab = query.tab.toString();
    }

    return apiQuery;
  }

  public override mapQueryFiltersToApiQueryFilters(queryFilters: HomePageQueryFilters): HomePageApiQueryFilters {
    const apiQueryFilters: HomePageApiQueryFilters = super.mapQueryFiltersToApiQueryFiltersIntrinsic(queryFilters);

    apiQueryFilters.animal = queryFilters.animal;
    apiQueryFilters.type = queryFilters.type;
    apiQueryFilters.geoHash = queryFilters.geoHash;
    apiQueryFilters.advertPublicCreatedAtPeriod = queryFilters.advertPublicCreatedAtPeriod;
    apiQueryFilters.gender = queryFilters.gender;
    apiQueryFilters.genderIncludeNotSet = queryFilters.genderIncludeNotSet;
    apiQueryFilters.sizes = queryFilters.sizes;

    return apiQueryFilters;
  }
}

export default HomePageQueryMapper;
