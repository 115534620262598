import type { Action, Reducer } from 'redux';
import { actionTypeClear, actionTypeFailed, actionTypeSucceeded } from '../../../actions/actionTypes';
import type { FSAP } from '../../../actions/base/fsa';
import type { BaseFailedPayload } from '../../../actions/models/payloads/base/BaseFailedPayload';
import type { BaseSucceededPayload } from '../../../actions/models/payloads/base/BaseSucceededPayload';
import { failedCaseReducer, succeedCaseReducer } from './fetchCaseReducers';
import type { FetchDataState } from './models/FetchDataState';

function fetchReducerFactory<
  TState extends FetchDataState<TData>,
  TData,
>(
  namespace: string,
  emptyState: TState,
): Reducer<
  TState,
  Action
> {

  const reducer: Reducer<
    TState,
    Action
  > = (
    state: TState | undefined,
    action: Action
  ): TState => {
      if (action.type.startsWith(`${namespace}/`)) {
        switch (action.type) {
          case `${namespace}/${actionTypeSucceeded}`:
            return succeedCaseReducer<TState, TData>(state ?? emptyState, action as FSAP<BaseSucceededPayload<TData>>);
          case `${namespace}/${actionTypeFailed}`:
            return failedCaseReducer<TState>(state ?? emptyState, action as unknown as FSAP<BaseFailedPayload>);
          case `${namespace}/${actionTypeClear}`:
            return emptyState;
        }
      }

      return state ?? emptyState;
    };

  return reducer;
}

export default fetchReducerFactory;
